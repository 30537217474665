@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300&display=swap");

body {
  background-color: rgb(31, 31, 31);
  font-family: "Fira Code", monospace;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
}

a {
  color: inherit;
  text-decoration: none !important;
  cursor: pointer;
}


.row {
  margin-left: 0;
  margin-right: 0;
}

.pageSection {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 1.1em;
}

.matrixHeader {
  position: relative;
  overflow: hidden;
  line-height: 0.85;
  font-weight: 300;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    z-index: 2;
    background-image: linear-gradient(
      to right,
      rgba(31, 31, 31, 1) 0%,
      rgba(31, 31, 31, 0) 30%,
      rgba(31, 31, 31, 0) 70%,
      rgba(31, 31, 31, 1) 100%
    );
  }
  .fillerText {
    color: rgba(255, 255, 255, 0.1);
  }
  .realText {
    color: rgba(255, 255, 255, 0.75);
    text-transform: uppercase;
    z-index: 3;
  }
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.prompt {
  animation-name: blink;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
